<template>
    <div>
        <div class="winex-wine-detail-wrap">
            <div class="winex-wine-detail-top-info-wrap">
                <div class="winex-wine-image-wrap" :style="{backgroundImage:`url(${membershipInfo.image}), url(${membershipInfo.fallbackImage})`}">

                </div>
                <div class="winex-wine-info-wrap">
                    <!-- 북마크 아이콘 -->
                    <!-- TODO :: 20220720 임시 주석 처리 -->
                    <!--<div class="bookmark-icon" @click="gettingReady"></div>-->

                    <!-- 와인 기본 정보 -->
                    <div class="default-wine-info-wrap">
                        <!--<h5 class="wine-brand">{{membershipInfo.brand}}</h5>-->
                        <h4 class="wine-title">{{membershipInfo.name}} </h4>
                    </div>

                    <!-- 와인 특성 정보 -->
                    <div class="detail-wine-info-wrap">
                        <div class="detail-wine-info-title-wrap" v-if="$isMobile()" @click="toggleCharacter">
                            <div>특성</div>
                            <div class="on-off-btn" :class="{off:membershipAttributeOpen}"></div>
                        </div>
                        <VueSlideToggle class="detail-wine-info-list" :open="membershipAttributeOpen" :duration="$isMobile()?200:0">
                            <div class="detail-wine-info-item" v-for="membershipAttribute in membershipInfo.attributes" v-bind:key="membershipAttribute.trait_type">
                                <span class="detail-wine-info-title">{{membershipAttribute.trait_type}}</span>
                                <span class="detail-wine-info-content">{{membershipAttribute.value}}</span>
                            </div>
                        </VueSlideToggle>
                    </div>

                    <div class="nft-link-info-wrap">
                        <div class="nft-link-col">
                            <div class="nft-link-title">Token ID</div>
                            <div class="nft-link-content"><a :href="`https://polygonscan.com/token/${$route.params.address}?a=${$route.params.tokenId}`" target="_blank">{{$route.params.tokenId}}</a></div>
                        </div>
                        <div class="nft-link-col">
                            <div class="nft-link-title">Contract Address</div>
                            <div class="nft-link-content"><a :href="`https://polygonscan.com/token/${$route.params.address}`" target="_blank">{{$route.params.address}}</a></div>
                        </div>
                    </div>

                    <!-- NFT 정보 -->
                    <div class="nft-wine-info-wrap">
                        <div class="nft-wine-info-table">
                            <div class="nft-wine-info-title-wrap">
                                <div class="nft-wine-info-title">소유자</div>
                                <div class="nft-wine-info-title">Sell Price</div>
                                <div class="nft-wine-info-title">Highest Offer</div>
                            </div>
                            <div class="nft-wine-info-content-wrap">
                                <div class="nft-wine-info-content">
                                    <span class="coin-price" style="word-break: break-all">{{membershipInfo.owner}}</span>
                                </div>

                                <div class="nft-wine-info-content">
                                    <div v-if="this.membershipInfo.status === 1">
                                        <span class="coin-price">
                                        <span>
                                            {{$numberFormat(membershipInfo.price.coin)}}
                                        </span>
                                    </span>
                                        <span class="won-price">
                                        ({{$numberFormat(membershipInfo.price.won).toString().split('.')[0]}}원)
                                    </span>
                                    </div>
                                </div>

                                <div class="nft-wine-info-content">
                                    <div v-if="this.membershipInfo.status !== 3">
                                        <span class="coin-price">
                                        <span>
                                            {{$numberFormat(membershipInfo.lastOffer.coin)}}
                                        </span>
                                    </span>
                                        <span class="won-price">
                                        ({{$numberFormat(membershipInfo.lastOffer.won).toString().split('.')[0]}}원)
                                    </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <span>· 1분전 환율, 와넥스 이용내역 기준입니다.</span>
                    </div>
                    <div v-if="!this.isNftSeller" class="wine-btn-wrap">
                        <!-- 구매자 -->
                        <DefaultButton
                            :content="'Make Offer'"
                            :disabled="!isBuyable || this.membershipInfo.status === 3"
                            :class-list="'wine-detail-btn make-offer-btn'"
                            :on-click="openSuggestModal" />
                        <DefaultButton
                            :content="'Buy Now'"
                            :disabled="!isBuyable || this.membershipInfo.status !== 1"
                            :class-list="'wine-detail-btn buy-now-btn'"
                            :on-click="openBidModal" />
                    </div>
                    <div v-else class="wine-btn-wrap">
                        <!-- 판매자 -->
                        <DefaultButton v-if="membershipInfo.status === 1"
                            :content="'Cancel'"
                            :class-list="'wine-detail-btn make-offer-btn'"
                            :on-click="openCancelModal" />
                        <DefaultButton v-else-if="membershipInfo.status === 2"
                            :content="'SELL'"
                            :class-list="'wine-detail-btn make-offer-btn'"
                            :on-click="openSellModel" />
                    </div>
                </div>
            </div>
            <div class="wine-info-wrap">
                <div class="wine-info-item">
                    <h3 class="wine-detail-title">Description</h3>
                    <div class="wine-description" v-html="membershipInfo.description">

                    </div>
                </div>
                <!--Offer List 표시부 -->
                <div class="wine-info-item" v-if="this.membershipInfo.status != 3 && this.isBuyable">
                    <h3 class="wine-detail-title">Offer List</h3>
                    <div class="item-activity-wrap">
                        <table class="winex-table">
                            <thead>
                                <tr>
                                    <th>User</th>
                                    <th>Price(USDC)</th>
                                    <th>Price(WON)</th>
                                    <th> </th>
                                </tr>
                            </thead>
                            <tbody v-for="item in this.offerList" :key="item.key">
                                <tr>
                                    <td>{{item.buyer}}</td>
                                    <td>
                                        <div class="double-line">
                                            <span class="main-data usdc-data"><span>{{$numberFormat(item.coin)}}</span></span>
                                        </div>
                                    </td>
                                    <td>{{$numberFormat(item.won).toString().split('.')[0]}}</td>
                                    <td v-if="item.enableAccept">
                                        <DefaultButton
                                            :content="'Accept'"
                                            :on-click="() => acceptNftOffer(item)"
                                            :class-list="'outline-white-lld-btn w120 h40'"
                                        ></DefaultButton>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- -->
                <div v-if="false">
                    <div class="wine-info-item">
                        <h3 class="wine-detail-title">Item Activity</h3>
                        <div class="item-activity-wrap">
                            <table class="winex-table">
                                <thead>
                                    <tr>
                                        <th>Event</th>
                                        <th>Date</th>
                                        <th>Price</th>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>TXID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- 여기 반복-->
                                    <tr>
                                        <td><span class="event-tag activity-sale">Sale</span></td>
                                        <td>
                                            <div class="double-line">
                                                <span class="main-data">2022.01.13</span>
                                                <span class="sub-data">(19:52:12)</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="double-line">
                                                <span class="main-data usdc-data"><span>5,500</span></span>
                                                <span class="sub-data">(3,000,000원)</span>
                                            </div>
                                        </td>
                                        <td>
                                            <span class="append-link">0x08x08e09x456x154fsd</span>
                                        </td>
                                        <td>
                                            <span class="append-link">0x08x08e09x456x154fsd</span>
                                        </td>
                                        <td>
                                            <DefaultButton
                                                :content="'View Detail'"
                                                :class-list="'outline-white-lld-btn w120 h40'"
                                            ></DefaultButton>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><span class="event-tag activity-transfer">Transfer</span></td>
                                        <td>
                                            <div class="double-line">
                                                <span class="main-data">2022.01.13</span>
                                                <span class="sub-data">(19:52:12)</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="double-line">
                                                <span class="main-data usdc-data"><span>5,500</span></span>
                                                <span class="sub-data">(3,000,000원)</span>
                                            </div>
                                        </td>
                                        <td>
                                            <span class="append-link">0x08x08e09x456x154fsd</span>
                                        </td>
                                        <td>
                                            <span class="append-link">0x08x08e09x456x154fsd</span>
                                        </td>
                                        <td>
                                            <DefaultButton
                                                    :content="'View Detail'"
                                                    :class-list="'outline-white-lld-btn w120 h40'"
                                            ></DefaultButton>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><span class="event-tag activity-list">List</span></td>
                                        <td>
                                            <div class="double-line">
                                                <span class="main-data">2022.01.13</span>
                                                <span class="sub-data">(19:52:12)</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="double-line">
                                                <span class="main-data usdc-data"><span>5,500</span></span>
                                                <span class="sub-data">(3,000,000원)</span>
                                            </div>
                                        </td>
                                        <td>
                                            <span class="append-link">0x08x08e09x456x154fsd</span>
                                        </td>
                                        <td>
                                            <span class="append-link">0x08x08e09x456x154fsd</span>
                                        </td>
                                        <td>
                                            <DefaultButton
                                                    :content="'View Detail'"
                                                    :class-list="'outline-white-lld-btn w120 h40'"
                                            ></DefaultButton>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><span class="event-tag activity-offer">Offer</span></td>
                                        <td>
                                            <div class="double-line">
                                                <span class="main-data">2022.01.13</span>
                                                <span class="sub-data">(19:52:12)</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="double-line">
                                                <span class="main-data usdc-data"><span>5,500</span></span>
                                                <span class="sub-data">(3,000,000원)</span>
                                            </div>
                                        </td>
                                        <td>
                                            <span class="append-link">0x08x08e09x456x154fsd</span>
                                        </td>
                                        <td>
                                            <span class="append-link">0x08x08e09x456x154fsd</span>
                                        </td>
                                        <td>
                                            <DefaultButton
                                                    :content="'View Detail'"
                                                    :class-list="'outline-white-lld-btn w120 h40'"
                                            ></DefaultButton>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <AlertModal
                :is-open="bidModalOpenCheck"
                :use-custom="true"
        >
            <template v-slot:custom>
                <div class="detail-modal-wrap bid-modal">
<!--                    <h2 class="detail-modal-title">즉시 입찰을 하시겠습니까?</h2>-->
                    <div class="detail-modal-content-wrap">
                        <h3 class="wine-name">{{membershipInfo.name}}</h3>
                    </div>
                    <div class="detail-modal-count-info-wrap">
                        <div class="detail-modal-count-info-row">
                            <div class="detail-modal-count-info-title">판매 가격</div>
                            <div class="detail-modal-count-info-content">
                                <div class="price-info-wrap">
                                    <span class="coin-price">{{$numberFormat(membershipInfo.price.coin)}}</span>
                                    <span class="won-price">({{$numberFormat(membershipInfo.price.won).toString().split('.')[0]}}원)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="buy-info-wrap">
                        <div class="buy-info-row">
                            <div class="buy-info-title">구매 가격</div>
                            <div class="buy-info-content">
                                <div class="price-info-wrap">
                                    <span class="coin-price">{{$numberFormat(sellCoinPrice)}}</span>
                                    <span class="won-price">({{$numberFormat(sellWonPrice).toString().split('.')[0]}}원)</span>
                                </div>
                            </div>
                        </div>
                        <!--<div class="buy-info-row">
                            <div class="buy-info-title">입찰 수량</div>
                            <div class="buy-info-content">
                                <div class="number-input-btn">
                                    <div class="number-btn minus-btn" @click="minusBidCount"></div>
                                    <input type="number" v-model="bidCount" />
                                    <div class="number-btn plus-btn" @click="plusBidCount"></div>
                                </div>

                            </div>
                        </div>-->
                    </div>
                    <div class="btn-wrap">
                        <DefaultButton
                                :content="'Cancel'"
                                :on-click="closeBidModal"
                                :class-list="'outline-white-lld-btn'"
                        >
                        </DefaultButton>
                        <DefaultButton
                                :content="'Buy Now'"
                                :on-click="submitNftBuy"
                                :class-list="'primary-btn'"
                        >
                        </DefaultButton>
                    </div>
                </div>
            </template>
        </AlertModal>
        <AlertModal
        :is-open="suggestModalOpenCheck"
        :use-custom="true">
            <template v-slot:custom>
                <div class="detail-modal-wrap suggest-modal">
                    <!--<h2 class="detail-modal-title">가격을 제시하시겠습니까?</h2>-->
                    <div class="detail-modal-content-wrap">
                        <h3 class="wine-name">{{membershipInfo.name}}</h3>
                    </div>
                    <div class="detail-modal-count-info-wrap">
                        <div class="detail-modal-count-info-row">
                            <div class="detail-modal-count-info-title">판매 가격</div>
                            <div class="detail-modal-count-info-content">
                                <div class="price-info-wrap">
                                    <span class="coin-price">{{$numberFormat(sellCoinPrice)}}USDC</span>
                                    <span class="won-price">({{$numberFormat(membershipInfo.price.won).toString().split('.')[0]}}원)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="buy-info-wrap">
                        <div class="buy-info-row select-date-row">
                            <div class="buy-info-title select-expire-date-title"><span>만료 기간</span></div>
                            <div class="buy-info-content">
                                <div class="select-expire-date">
                                    <DefaultDropDown :list-item="dropDownDateArray"
                                    :selectedData.sync="dropDownSelectDate"
                                    :list-height="40"></DefaultDropDown>
                                    <input type="time" class="select-time">
                                </div>
                            </div>
                        </div>
                        <div class="buy-info-row">
                            <div class="buy-info-title">제시 수량</div>
                            <div class="buy-info-content">
                                <div class="number-input-btn">
                                    <div class="number-btn minus-btn" @click="minusSuggestCount"></div>
                                    <input type="number" v-model="suggestCount"/>
                                    <div class="number-btn plus-btn" @click="plusSuggestCount"></div>
                                </div>

                            </div>
                        </div>
                        <div class="buy-info-row">
                            <div class="buy-info-title">
                                <div class="buy-info-double-line suggest-price-title">
                                    <span>제시 가격</span>
                                    <span class="small-buy-title"></span>
                                </div>
                            </div>
                            <div class="buy-info-content">
                                <div class="price-info-wrap">
                                    <div class="buy-info-double-line" style="align-items:flex-end;">
                                        <input type="number" class="winex-input input-suggest-price" v-model="suggestPrice">
                                        <span class="exchange-price">({{$numberFormat(suggestPriceToWon).toString().split('.')[0]}}원)</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn-wrap">
                        <DefaultButton
                                :content="'Close'"
                                :on-click="closeSuggestModal"
                                :class-list="'outline-white-lld-btn'"
                        >
                        </DefaultButton>
                        <DefaultButton
                                :content="'Make Offer'"
                                :on-click="submitNftOffer"
                                :class-list="'primary-btn'"
                        >
                        </DefaultButton>
                    </div>
                </div>
            </template>
        </AlertModal>
        <AlertModal
        :is-open="sellModelOpenCheck"
        :use-custom="true">
            <template v-slot:custom>
                <div class="detail-modal-wrap suggest-modal">
                    <!--<h2 class="detail-modal-title">판매 가격을 제시하시겠습니까?</h2>-->
                    <div class="detail-modal-content-wrap">
                        <h3 class="wine-name">{{membershipInfo.name}}</h3>
                    </div>
                    <div class="buy-info-wrap">
                        <div class="buy-info-row">
                            <div class="buy-info-title">
                                <div class="buy-info-double-line suggest-price-title">
                                    <span>판매 가격</span>
                                    <span class="small-buy-title">USDC</span>
                                </div>
                            </div>
                            <div class="buy-info-content">
                                <div class="price-info-wrap">
                                    <div class="buy-info-double-line" style="align-items:flex-end;">
                                        <input type="number" class="winex-input input-suggest-price" v-model="sellPrice">
                                        <span class="exchange-price">({{$numberFormat(sellPriceToWon).toString().split('.')[0]}}원)</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn-wrap">
                        <DefaultButton
                                :content="'Close'"
                                :on-click="closeSellModal"
                                :class-list="'outline-white-lld-btn'"
                        >
                        </DefaultButton>
                        <DefaultButton
                                :content="'Sell'"
                                :on-click="submitNftSell"
                                :class-list="'primary-btn'"
                        >
                        </DefaultButton>
                    </div>
                </div>
            </template>
        </AlertModal>
        <AlertModal
        :is-open="cancelModelOpenCheck"
        :use-custom="true">
            <template v-slot:custom>
                <div class="detail-modal-wrap suggest-modal">
                    <!--<h2 class="detail-modal-title">NFT 판매를 취소하시겠습니까?</h2>-->
                    <div class="detail-modal-content-wrap">
                        <h3 class="wine-name">{{membershipInfo.name}}</h3>
                    </div>
                    <div class="buy-info-wrap">
                        <div class="buy-info-row">
                            <div class="buy-info-title">
                                <div class="buy-info-double-line suggest-price-title">
                                    <span>판매 가격</span>
                                    <span class="small-buy-title">USDC</span>
                                </div>
                            </div>
                            <div class="buy-info-content">
                                <div class="price-info-wrap">
                                    <div class="buy-info-double-line" style="align-items:flex-end;">
                                        <span class="exchange-price">{{$numberFormat(membershipInfo.price.coin)}}</span>
                                        <span class="exchange-price">({{$numberFormat(membershipInfo.price.won).toString().split('.')[0]}}원)</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn-wrap">
                        <DefaultButton
                                :content="'Close'"
                                :on-click="closeCancelModal"
                                :class-list="'outline-white-lld-btn'"
                        >
                        </DefaultButton>
                        <DefaultButton
                                :content="'Cancel'"
                                :on-click="submitNftSellCancel"
                                :class-list="'primary-btn'"
                        >
                        </DefaultButton>
                    </div>
                </div>
            </template>
        </AlertModal>

        <AlertModal
            :title="modal.title"
            :content="modal.content"
            :showWait="modal.showWait"
            :pcWidth="500"
            :button-content="'확인'"
            :button-class="'primary-btn'"
            :is-open="flagModalOnOff"
            :confirm-click-btn="closeModal">
        </AlertModal>
    </div>
</template>
<script>
    import DefaultButton from "../../components/buttons/DefaultButton";
    import {VueSlideToggle} from 'vue-slide-toggle'
    import getContractApi from "../../api/contract";

    import AlertModal from "../../components/modals/AlertModal";
    import DefaultDropDown from "../../components/dropdowns/DefaultDropDown";
    //import { isHexString } from "@ethersproject/bytes";
    export default {
        components: {DefaultDropDown, AlertModal, DefaultButton,VueSlideToggle},
        created() {
            this.contract = getContractApi();
            this.market = this.contract.getMembershipMarket();
            const address = this.$route.params.address;
            const tokenId = this.$route.params.tokenId;

            this.membershipInfo.key = `${address}/${tokenId}`;
            this.tokenId = tokenId;
        },
        mounted() {
            this.refreshRate = 1000 * 5;
            // @TODO 로딩 시작
            this.update().finally(() => {
                // @TODO 로딩 끝
            });
            this.doUpdateActivities();
            this.updateActivities();
        },
        beforeDestroy() {
            this.refreshRate = 0;
            // 통신 이후 데이터 세팅

        },
        data () {
            return  {
                contract: null,
                market: null,
                refreshRate: 15000,
                tokenId: 0,
                isBuyable: true,
                membershipInfo: {
                    owner: "0x0",
                    price: { coin: 0, won: 0 },
                    lastOffer: { buyer: "0x0", coin: 0, won: 0 },
                },
                offerList: [],
                membershipAttributeOpen: true,
                bidModalOpenCheck:false,
                suggestModalOpenCheck: false,
                sellModelOpenCheck:false,
                cancelModelOpenCheck: false,
                isNftSeller:false,
                modal : {
                    title: "",
                    content: ""
                },
                dropDownDateArray:[
                    {
                        value:1,
                        text:"1일"
                    },
                    {
                        value:3,
                        text:"3일"
                    },
                    {
                        value:7,
                        text:"7일"
                    },
                ],
                dropDownSelectDate:3,

                bidCount:1,
                bidPrice:0,

                exchangeRate:2.7,

                suggestCount:1,
                suggestPrice:0,
                sellPrice:0,
                flagModalOnOff: false
            }
        },
        watch: {
        },
        methods: {
            gettingReady() {
                alert('준비중 입니다.')
            },
            toggleCharacter() {
                this.membershipAttributeOpen = !this.membershipAttributeOpen
            },
            setModal(title, content) {
                this.modal.title = title
                this.modal.content = content
            },
            setModalWait(showWait) {
                this.modal.showWait = showWait
            },
            async update() {
                // TODO :: Membership NFT 정보 가져오는 통신 부분
                const info = await this.market.fetchItemInfoByTokenId(this.tokenId, true);
                const { publisher } = await this.contract.ensureConnection();
                this.isBuyable = info.owner !== publisher.address;

                const description = `
                   <div class="sc-1xf18x6-0 haVRLx item--description-text">
                        <div class="sc-edvquz-0 fWQpLM">
                            <p>${info.description.replaceAll('\n', "<br/>")}</p>
                        </div>
                    </div>
                `;
                const pattern = /https?:\/\/[^/]+\/ipfs\/(.+)/;
                const matched = info.image?.match(pattern);
                if (matched) {
                    info.fallbackImage = info.image;
                    info.image = `https://winex-s3.s3.ap-northeast-2.amazonaws.com/ipfs/${matched[1]}`;
                }
                this.membershipInfo = {
                    ...this.membershipInfo, ...info,
                    description,
                }

                let userAddress = await this.market.getUserAddress();
                this.isNftSeller = userAddress == this.membershipInfo.owner ? true : false;
            },
            async updateActivities() {
                const ms = this.refreshRate;
                if (ms <= 0)
                    return;
                setTimeout(async () => {
                    try {
                        if (this.refreshRate > 0 && !this.suggestModalOpenCheck)
                            await this.doUpdateActivities();
                    } finally {
                        this.updateActivities(this.refreshRate);
                    }
                }, ms);
            },
            async doUpdateActivities() {
                let offers = await this.market.fetchOffers(this.tokenId);
                let userAddress = await this.contract.getUserAddress();
                let enableAcceptBnt = userAddress == this.membershipInfo.owner ? true : false;
                if (this.membershipInfo?.lastOffer === undefined)
                    return;

                this.offerList = [];
                let highest = undefined;
                const entries = [...offers.entries()].sort(([, l], [, r]) => {
                    if (l.price.gt(r.price))
                        return -1;
                    return 0;
                });
                for (const [index, item] of entries) {
                    const coin = this.contract.formatUsdc(item.price);
                    const offer = {
                        buyer: item.buyer
                        , coin
                        , coinOrigin: item.price
                        , won: this.contract.toWon(coin)
                        , key: index
                        , enableAccept : enableAcceptBnt
                    };
                    highest = !highest ? offer : (item.price.lt(highest.coinOrigin) ? highest : offer);
                    this.offerList.push(offer);
                }

                if (highest) {
                    this.$set(this.membershipInfo.lastOffer, "buyer", highest.buyer);
                    this.$set(this.membershipInfo.lastOffer, "coin", highest.coin);
                    this.$set(this.membershipInfo.lastOffer, "won", highest.won);
                } else {
                    this.$set(this.membershipInfo.lastOffer, "buyer", 0);
                    this.$set(this.membershipInfo.lastOffer, "coin", 0);
                    this.$set(this.membershipInfo.lastOffer, "won",0);
                }
            },
            closeBidModal() {
                this.bidModalOpenCheck = false
            },
            closeSuggestModal() {
                this.suggestModalOpenCheck = false
            },
            closeSellModal() {
                this.sellModelOpenCheck = false
            },

            closeCancelModal() {
                this.cancelModelOpenCheck = false
            },
            openSuggestModal() {
                this.suggestPrice = this.membershipInfo.price.coin;
                this.suggestModalOpenCheck = true
            },
            openBidModal() {
                this.bidModalOpenCheck = true
            },
            openSellModel() {
                this.sellModelOpenCheck = true;
            },
            openCancelModal() {
                this.cancelModelOpenCheck = true;
            },

            minusBidCount() {
                if(this.bidCount > 1) {
                    this.bidCount --
                }
            },
            plusBidCount() {
                if(this.remainingQuantity > this.bidCount) {
                    this.bidCount++
                }
            },
            minusSuggestCount() {
                if(this.suggestCount > 1) {
                    this.suggestCount--
                }
            },
            plusSuggestCount() {
                if(this.remainingQuantity > this.suggestCount) {
                    this.suggestCount++
                }
            },
            submitNftOffer() {
                this.suggestModalOpenCheck = false;
                const titleMsg = "Make Offer";
                this.setModalWait("hide");
                this.$store.commit('loading/init',{title:titleMsg, content:'진행중'})
                const nPrice  = this.contract.parseUsdc(this.suggestPrice.toString());
                this.withLogin(() => {
                    return this.market.offerNft(this.tokenId, nPrice, (statusMsg,stVal) => {
                        console.log(stVal)
                        this.$store.commit('loading/init',{title:titleMsg, content:statusMsg})
                    });
                }).then(() => {
                    this.flagModalOnOff = true;
                    this.setModal(titleMsg, "Make Offer가 완료되었습니다.");
                    this.$store.commit('loading/done')
                }).catch(e => {
                    console.log(e);
                    this.flagModalOnOff = true;
                    this.$store.commit('loading/done');
                    if (e.name === "UserFriendlyError" && e.message) {
                        this.setModal("오류", e.message);
                    } else {
                        this.setModal("오류", "Make Offer를 실패하였습니다.");
                    }
                });
            },
            submitNftBuy() {
                this.bidModalOpenCheck = false;
                const titleMsg = "바로 구매";
                this.setModalWait("hide");
                this.$store.commit('loading/init',{title:titleMsg, content:'계좌 잔고 확인 중입니다!(1/2)'})
                this.withLogin(() => {
                    return this.market.buyNowNft(this.tokenId, (statusMsg,stVal) => {
                        console.log(stVal)
                         this.$store.commit('loading/init',{title:titleMsg, content:statusMsg})
                    });
                }).then(() => {
                     this.flagModalOnOff = true;
                    this.setModal(titleMsg, "바로 구매가 완료되었습니다");
                    this.$store.commit('loading/done')
                }).catch(e => {
                    console.log(e);
                    this.flagModalOnOff = true;
                    this.$store.commit('loading/done');
                    if (e.name === "UserFriendlyError" && e.message) {
                        this.setModal("오류", e.message);
                    } else {
                        this.setModal("오류", "NFT 구매를 실패하였습니다");
                    }
                });
            },
            acceptNftOffer(offer) {
                this.suggestModalOpenCheck = false;
                const titleMsg = "제안가격 수락";
                this.setModalWait("hide"); 
                this.$store.commit('loading/init',{title:titleMsg, content:'계좌 잔고 확인중입니다. (1/2)'})
                this.withLogin(() => {
                    return this.market.acceptNftOffer(this.tokenId, offer.buyer, offer.coinOrigin,  (statusMsg,stVal) => {
                        console.log(stVal)
                        this.$store.commit('loading/init',{title:titleMsg, content:statusMsg})
                    });
                }).then(() => {
                    this.flagModalOnOff = true;
                    this.setModal(titleMsg, "가격제안 수락이 완료되었습니다");
                    this.$store.commit('loading/done')
                }).catch(e => {
                    console.log(e);
                    this.flagModalOnOff = true;
                    this.$store.commit('loading/done')
                    this.setModal("오류", "가격 제안 수락을 실패하였습니다.");
                });
            },
            submitNftSell() {
                this.sellModelOpenCheck = false;
                const titleMsg = "판매 등록";
                this.setModalWait("hide");
                this.$store.commit('loading/init',{title:titleMsg, content:'제안가격으로 판매 등록 중입니다.(1/2)'})
                //@ToDo:판매가격 오류 및 이상동작을 이곳에서 판단하여 처리함
                this.withLogin(() => {
                    return this.market.sellNft(this.tokenId, this.sellPrice,  (statusMsg,stVal) => {
                       console.log(stVal)
                       this.$store.commit('loading/init',{title:titleMsg, content:statusMsg})
                    });
                }).then(() => {
                    this.flagModalOnOff = true;
                    this.setModal(titleMsg, "제안가격으로 판매 등록 완료되었습니다.");
                    this.$store.commit('loading/done')
                }).catch(e => {
                    console.log(e);
                    this.$store.commit('loading/done')
                    this.flagModalOnOff = true;
                    this.setModal("오류", "판매 등록을 실패하였습니다.");
                });
            },
            submitNftSellCancel() {
                this.cancelModelOpenCheck = false;
                const titleMsg = "판매 취소";
                this.setModalWait("hide");
                this.$store.commit('loading/init',{title:titleMsg, content:'판매등록을 취소중입니다.(1/1)'})

                //@ToDo:판매가격 오류 및 이상동작을 이곳에서 판단하여 처리함
                this.withLogin(() => {
                    return this.market.cancelListing(this.tokenId,  (statusMsg,stVal) => {
                        console.log(stVal)
                        this.$store.commit('loading/init',{title:titleMsg, content:statusMsg})
                    })
                }).then(() => {
                    this.flagModalOnOff = true;
                    this.setModal(titleMsg, "판매 취소가 완료되었습니다.");
                    this.$store.commit('loading/done')
                }).catch(e => {
                    console.log(e);
                    this.flagModalOnOff = true;
                    this.$store.commit('loading/done')
                    this.setModal("오류", "판매 취소가 실패하였습니다.");
                });
            },
            submitNftRedeem() {
                const titleMsg = "REDEEM 요청";
                this.redeemModelOpenCheck = false;
                this.setModalWait("hide");
                this.$store.commit('loading/init',{title:titleMsg, content:'연결 확인 중입니다.(1/2)'})
                 this.withLogin(() => {
                    return this.market.requestRedeemNft(this.tokenId, false,  (statusMsg,stVal) => {
                        console.log(stVal)
                        this.$store.commit('loading/init',{title:titleMsg, content:statusMsg})
                    })
                }).then(() => {
                    this.flagModalOnOff = true;
                    this.$store.commit('loading/done')
                    this.setModal(titleMsg, "REDEEM 요청이 완료되었습니다.");
                }).catch(e => {

                    console.log(e);
                    this.flagModalOnOff = true;
                    this.$store.commit('loading/done')
                    this.setModal("오류", "REDEEM 요청이 실패하였습니다.");
                });
            },
            closeModal() {
                // 예외 분기 처리 필요 : ex. 민팅 실패 시, TIME OUT 시
                // ---
                // 모달 닫기
                this.flagModalOnOff = false;
               // location.reload();
                this.update();
                // 민팅 정상 완료 시 이동
                // if (this.createdNFT) {
                //     this.$router.push(`/market/detail/${this.createdNFT}`);
                // }
            },
            withLogin(routine) {
                return this.contract.getUserAddress().then(address => {
                    if (!address)
                        throw Error("지갑을 연결해주세요.");
                    return routine(address);
                });
            }
        },
        computed: {
            suggestPriceToWon(){
                return this.contract.toWon(this.suggestPrice);
            },
            sellPriceToWon(){
                return this.contract.toWon(this.sellPrice);
            },
            sellCoinPrice(){
                return this.membershipInfo.price.coin * this.bidCount;
            },
            sellWonPrice(){
                return this.membershipInfo.price.won * this.bidCount;
            }
        }
    }
</script>

<style scoped lang="scss">
@import "@/assets/scss/nft-detail.scss";
</style>
